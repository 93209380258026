@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'trajanPro';
  src: url('../assets/trajanpro-regular-webfont.woff2') format('woff2'),
    url('../assets/trajanpro-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  border-radius: 100%;
  appearance: none;
  width: 22px;
  /* Set a specific slider handle width */
  height: 22px;
  /* Slider handle height */
  background: #fff;
  /* Green background */
  border: 3px solid #a87845;
  cursor: pointer;
  /* Cursor on hover */
}

.slider::-moz-range-thumb {
  border-radius: 100%;
  width: 22px;
  /* Set a specific slider handle width */
  height: 22px;
  /* Slider handle height */
  background: #fff;
  /* Green background */
  border: 3px solid #a87845;
  cursor: pointer;
  /* Cursor on hover */
}

.skew-container {
  margin: 0 -10vw
}

.overflowy-hidden {
  overflow-x: hidden;
}

.fill-skew {
  width: 120%;
  max-width: 120%;
  height: 110%;
  left: -10%;
  position: absolute;
}



.fill-skew-sm {
  width: 100%;
  max-width: 100%;
  height: 160%;
  top: -30%;
  position: absolute;
}

.width-trans:hover {
  flex: 2;
}

.width-trans {
  flex: 1;
  transition: all 0.3s;
}

@media (max-width: 1024px) {}

.h-trans:active {
  height: 40rem;
}

.h-trans {
  transition: all 0.3s;
}




/* .hidden-room-card {
  opacity: 0;
  transform: translateY(20px);
}

.shown-room-card {
  opacity: 1;
  transform: translateY(0);
  transition-duration: 1200ms;
} */

/* .room-card:nth-child(2) {
  transition-delay: 400ms;
}
.room-card:nth-child(3) {
  transition-delay: 800ms;
} */

.hidden-feature {
  opacity: 0;
  transform: translateY(20px);
}

.shown-feature {
  opacity: 1;
  transform: translateY(0);
  transition-duration: 1200ms;
}